import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

export const Logout = () => {
    const navigate = useNavigate();
    useAuth().signOut();



    return (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 z-50 flex justify-center items-center text-white backdrop-blur-sm">
            <div className="flex flex-col justify-center items-center">
                {/* CSS Spinner */}
                <div className="bg-slate-100 p-4 mb-10 rounded-md">
                    <img src={process.env.PUBLIC_URL + '/logo.png'} className="m-auto h-8" alt="Logo" />
                </div>
                <p className="mb-6">You have been logged out. You may close this window.</p>
                <button onClick={() => navigate("/login")} className="text-sky-300">
                    Log me back in
                </button>
            </div>
        </div>
    );
};