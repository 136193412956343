import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { SortDirection } from 'types/Article';

interface SortDirectionProps {
    onChange: (sortDirection: SortDirection) => void;
    activeDirection: SortDirection
}

const SortDirectionComponent: React.FC<SortDirectionProps> = ({ onChange, activeDirection }) => {
    return (
        <div className="my-4 flex rounded-md border-2 border-gray-200 bg-white shadow-sm">
            <button
                onClick={() => onChange(SortDirection.Asc)}
                className={`${activeDirection === SortDirection.Asc ? 'bg-gray-200' : 'hover:bg-gray-100'
                    } p-2 transition-colors duration-150 rounded-l-md w-full`}
            >
                <ArrowDownIcon
                    className={`h-5 w-5 ${activeDirection === SortDirection.Asc ? 'text-gray-900' : 'text-gray-500'}`}
                    aria-hidden="true"
                />
            </button>
            <button
                onClick={() => onChange(SortDirection.Desc)}
                className={`${activeDirection === SortDirection.Desc ? 'bg-gray-200' : 'hover:bg-gray-100'
                    } p-2 transition-colors duration-150 rounded-r-md w-full`}
            >
                <ArrowUpIcon
                    className={`h-5 w-5 ${activeDirection === SortDirection.Desc ? 'text-gray-900' : 'text-gray-500'}`}
                    aria-hidden="true"
                />
            </button>
        </div>
    );
};

export default SortDirectionComponent;