import { AccessToken, Role } from "hooks/auth/Token";
import RoleRenderer from "./RoleRenderer";


interface RoleRendererSwitchProps {
    renderers: Map<Role, (token: AccessToken) => React.ReactNode>
}

const RoleRendererSwitch: React.FC<RoleRendererSwitchProps> = ({ renderers }) => {
    return <>
        {Array.from(renderers).map(([role, renderer]) => <RoleRenderer key={role} allowedRoles={[role]} elementRenderer={renderer} />)}
    </>
}

export default RoleRendererSwitch;