import React, { Fragment, useEffect, useState } from 'react';
import { DocumentTextIcon, XMarkIcon, ViewColumnsIcon, ListBulletIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { Dialog, Transition } from '@headlessui/react';
import { useArticles, useListPrice } from 'hooks/article/Article';
import { Article, ArticleQuery, SortField } from 'types/Article';
import SearchField from 'components/SearchField';
import AccountList from './AccountList';
import ArticleCard from './ArticleCard';
import Pagination from './Pagination';
import SortDirectionComponent from './SortDirectionComponent';
import { Role } from 'hooks/auth/Token';
import RoleRendererSwitch from './RoleRendererSwitch';
import { Table, TextAlignment } from './Table';
import { ListPrice } from './ListPrice';
import { useAccountDetails } from 'hooks/customer/Customer';
import Decimal from 'decimal.js';

interface ArticleSearchProps {
  companyId: string,
  accountId?: string
}

interface Price {
  articleId: string
  price: Decimal
}

const ArticleSearch: React.FC<ArticleSearchProps> = ({ companyId, accountId }) => {
  const sortFieldMap = new Map<SortField, string>();
  sortFieldMap.set(SortField.ArticleID, "Art. No.");
  sortFieldMap.set(SortField.Category, "Product family");
  sortFieldMap.set(SortField.Description, "Description");
  sortFieldMap.set(SortField.Price, "Sales Price");
  sortFieldMap.set(SortField.CostPrice, "Cost price");

  const { accountDetails, updateAccountDetailsFilter } = useAccountDetails(companyId, accountId);
  const { articles, activeFilter, totalCount, isLoading, updateFilter } = useArticles(companyId, accountId === undefined ? null: accountId);
  const [selectedArticle] = useState<Article | null>(null);
  const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);
  const [viewMode, setViewMode] = useState<'list' | 'grid'>('list');
  const [showFilters, setShowFilters] = useState(false);
  const {listPrices, setListPriceFilter} = useListPrice();
  const [allListPrices, setAllListPrices] = useState<Price[]>([])
  const [account, setAccount] = useState<string | undefined>(accountId)
    
  useEffect(() => {
    setListPriceFilter(null)
    if (accountDetails === null || articles.length === 0 || account === undefined) {
      return;
    }

    setListPriceFilter({
      ids: articles.map((a) => a.id),
      accountNumber: account,
      currency: articles[0].currency,
      discountGroup: accountDetails!.discountGroup,
      priceGroup: accountDetails!.priceGroup,
      salesCompanyId: companyId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountDetails, articles.join(","), account, companyId])

  useEffect(() => {
    updateFilter('accountId', null);
    setAllListPrices([]);
    if (account === undefined) {
      return;
    }
    
    updateAccountDetailsFilter('number', account);
    updateFilter('accountId', account);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  useEffect(() => {
    var res: Price[] = [...allListPrices];
    var prices: {articleId: string, price: Decimal}[] = listPrices !== null ? listPrices.prices: [];
    for (var i = 0; i < prices.length; i++) {
      var lp = prices[i];
      var exists = allListPrices.includes(lp)
      if (!exists) {
        var price = {
          articleId: lp.articleId,
          price: lp.price,
        }
        res.push(price);
      }

    }

    setAllListPrices(res);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listPrices, setAllListPrices])

  const closeSlideOver = () => {
    setIsSlideOverOpen(false);
  };

  const toggleViewMode = () => {
    setViewMode(viewMode === 'grid' ? 'list' : 'grid');
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const renderAdditionalFilters = () => {
    if (!showFilters) return null;

    const filterKeys: Array<keyof ArticleQuery> = ['category', 'description'];

    const filterNames = new Map<string, string>([
      ['category', 'product family'],
      ['description', 'description'],

    ]);

    return filterKeys.map((key) => (
      <div className='py-2' key={key}>
        <SearchField placeholder={`Search by ${filterNames.get(key)}`} onSearch={(query) => updateFilter(key, query)} autofocus={false} />
      </div>
    ));
  };

  const renderSortOptions = (sortFields: SortField[]) => {
    var result: JSX.Element[] = [];
    sortFields.forEach((value) => result.push((
      <button
        key={value}
        disabled={activeFilter.accountId === null}
        onClick={() => updateFilter('sortField', value)}
        className={`${activeFilter.sortField === value ? 'bg-gray-200' : 'hover:bg-gray-100'
          } p-2 transition-colors duration-150 rounded-l-md w-full`}
      >
        {sortFieldMap.get(value)}
      </button>
    )));

    return result;
  };

  const updateAccount = (number: string | undefined) => {
    setAccount(number)
  };

  return (
    <div className='items-center justify-center p-4'>
      <RoleRendererSwitch renderers={
        new Map([
          [
            Role.SalesPerson, (token) => {
              return (
                <div className='md:xl:max-w-screen-md w-full m-auto'>
                  <AccountList salesCompanyId={token.activeRole.erpDataId} onSelected={(account) => updateAccount(account.number)} onSearch={() => updateAccount(undefined)}/>
                </div>
              );
            }
          ],
        ])
      } />

      <div className="flex flex-col items-center justify-center p-4 relative">
        {/* Search Field */}
        <SearchField onSearch={(query: string) => { updateFilter("articleId", query) }} placeholder='Search by art. no.' autofocus={activeFilter.accountId !== null} />

        {/* More Filters Button */}
        <div className="my-4">
          <button
            onClick={toggleFilters}
            className="flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            More filters
            {showFilters ? (
              <ChevronUpIcon className="ml-2 h-5 w-5" aria-hidden="true" />
            ) : (
              <ChevronDownIcon className="ml-2 h-5 w-5" aria-hidden="true" />
            )}
          </button>
        </div>

        {/* Additional Filters with Transition */}
        <Transition
          as={Fragment}
          show={showFilters}
          enter="transition ease-out duration-300"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition ease-in duration-200"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <div className="origin-top w-full max-w-md">
            {renderAdditionalFilters()}
          </div>
        </Transition>


        {/* Sort buttons */}
        <p className="mt-2">Sort by</p>
        <div className="my-4 flex rounded-md border-2 border-gray-200 bg-white shadow-sm">
          <RoleRendererSwitch renderers={
            new Map([
              [
                Role.SalesPerson, (token) => {
                  return (
                    <>
                      {renderSortOptions([SortField.ArticleID, SortField.Category, SortField.Description])}
                    </>
                  );
                }
              ],
              [
                Role.Customer, (token) => {
                  return (
                    <>
                      {renderSortOptions([SortField.ArticleID, SortField.Category, SortField.Description])}
                    </>
                  );
                }
              ],
            ])
          } />

        </div>

        <p className="mt-2">Sort direction</p>
        <SortDirectionComponent activeDirection={activeFilter.sortDirection} onChange={(sortDirection) => updateFilter('sortDirection', sortDirection)} />

        {/* Toggle View Mode Tabs */}
        <div className="my-4 flex rounded-md border-2 border-gray-200 bg-white shadow-sm">
          <button
            disabled={activeFilter.accountId === null}
            onClick={() => toggleViewMode()}
            className={`${viewMode === 'list' ? 'bg-gray-200' : 'hover:bg-gray-100'
              } p-2 transition-colors duration-150 rounded-l-md w-full`}
          >
            <ListBulletIcon
              className={`h-5 w-5 ${viewMode === 'list' ? 'text-gray-900' : 'text-gray-500'}`}
              aria-hidden="true"
            />
          </button>
          <button
            onClick={() => toggleViewMode()}
            className={`${viewMode === 'grid' ? 'bg-gray-200' : 'hover:bg-gray-100'
              } p-2 transition-colors duration-150 rounded-r-md w-full`}
          >
            <ViewColumnsIcon
              className={`h-5 w-5 ${viewMode === 'grid' ? 'text-gray-900' : 'text-gray-500'}`}
              aria-hidden="true"
            />
          </button>
        </div>

        {/* Search Results */}
        <div className="mt-8 mb-12 max-w-full overflow-y-scroll">
          {articles.length > 0 && accountDetails !== null ? (
            viewMode === 'grid' ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {articles.map((article: Article) => (
                  <RoleRendererSwitch renderers={
                    new Map([
                      [
                        Role.SalesPerson, (token) => {
                          return (<ArticleCard key={article.id} article={article} onClick={() =>{}} values={[
                            {
                              header: "Description",
                              value: (item: Article) => item.description
                            },
                            {
                              header: "Product family",
                              value: (item: Article) => item.category
                            },
                            {
                              header: "Gross price",
                              value: (item: Article) => new Intl.NumberFormat('en-US', { style: 'currency', currency: item.currency }).format(item.price.toNumber()),
                            },
                            {
                              header: "Partner price",
                              value: (item: Article) => <ListPrice article={item} listPrice={allListPrices.find((i) => i.articleId === item.id)?.price} />,
                            },
                            {
                              header: "Cost price",
                              value: (item: Article) => new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(item.costPrice!.toNumber()),
                            },
                          ]} />)
                        }],
                      [
                        Role.Customer, (token) => {
                          return (<ArticleCard key={article.id} article={article} onClick={() => {}} values={[
                            {
                              header: "Description",
                              value: (item: Article) => item.description
                            },
                            {
                              header: "Product family",
                              value: (item: Article) => item.category
                            },
                            {
                              header: "Gross price",
                              value: (item: Article) => new Intl.NumberFormat('en-US', { style: 'currency', currency: item.currency }).format(item.price.toNumber()),
                            },
                            {
                              header: "Partner price",
                              value: (item: Article) => <ListPrice article={item} listPrice={allListPrices.find((i) => i.articleId === item.id)?.price} />,
                            },
                          ]} />)
                        }]
                    ])
                  } />

                ))}
              </div>
            ) : (
              <RoleRendererSwitch renderers={
                new Map([
                  [
                    Role.SalesPerson, (token) => {
                      return (

                        <Table id={activeFilter.accountId!} items={articles} cells={[
                          {
                            header: "Art. No.",
                            value: (item: Article) => item.id
                          },
                          {
                            header: "Description",
                            value: (item: Article) => item.description
                          },
                          {
                            header: "Product family",
                            value: (item: Article) => item.category
                          },
                          {
                            header: "Gross price",
                            value: (item: Article) => new Intl.NumberFormat('en-US', { style: 'currency', currency: item.currency }).format(item.price.toNumber()),
                            textAlignment: TextAlignment.Right
                          },
                          {
                            header: "Partner price",
                            value: (item: Article) => <ListPrice article={item} listPrice={allListPrices.find((i) => i.articleId === item.id)?.price} />,
                            textAlignment: TextAlignment.Right
                          },
                          {
                            header: "Cost price",
                            value: (item: Article) => new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(item.costPrice!.toNumber()),
                            textAlignment: TextAlignment.Right
                          },
                        ]} />
                      );
                    }
                  ],
                  [
                    Role.Customer, (token) => {
                      return (
                        <Table id={activeFilter.accountId!} items={articles} cells={[
                          {
                            header: "Art. No.",
                            value: (item: Article) => item.id
                          },
                          {
                            header: "Description",
                            value: (item: Article) => item.description
                          },
                          {
                            header: "Product family",
                            value: (item: Article) => item.category
                          },
                          {
                            header: "Gross price",
                            value: (item: Article) => new Intl.NumberFormat('en-US', { style: 'currency', currency: item.currency }).format(item.price.toNumber()),
                            textAlignment: TextAlignment.Right
                          },
                          {
                            header: "Partner price",
                            value: (item: Article) => <ListPrice article={item} listPrice={allListPrices.find((i) => i.articleId === item.id)?.price} />,
                            textAlignment: TextAlignment.Right
                          },
                        ]} />
                      );
                    }
                  ],
                ])
              } />
            )
          ) : (
            <div className="flex flex-col items-center justify-center p-20">
              <DocumentTextIcon className="h-12 w-12 text-gray-400" />
              <p className="text-gray-400 mt-2">No articles</p>
            </div>
          )}



        </div>
        {articles.length > 0 && (
          <Pagination
            currentPage={activeFilter.page}
            totalPages={Math.ceil(totalCount / activeFilter.limit)}
            onPageChange={(page) => {
              updateFilter('page', page)
            }}
          />)}


        {/* Slide-over Panel for Article Details */}
        <Transition.Root show={isSlideOverOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setIsSlideOverOpen}>
            {/* Overlay */}
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-300 sm:duration-500"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-300 sm:duration-500"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                      <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                        <div className="px-4 py-4 bg-gray-100 flex justify-between items-center">
                          <h2 className="text-lg font-medium text-gray-900">Article Details</h2>
                          <button onClick={closeSlideOver}>
                            <XMarkIcon className="h-6 w-6 text-gray-500" />
                          </button>
                        </div>
                        <div className="p-6">
                          {selectedArticle && (
                            <>
                              <p className="text-sm font-medium text-gray-500">ID: {selectedArticle.id}</p>
                              <p className="text-lg font-bold text-gray-900 mb-4">{selectedArticle.description}</p>
                              {/* Render additional article details here */}
                            </>
                          )}
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        {!activeFilter.accountId && (
          <div className="absolute inset-0 backdrop-blur-sm flex justify-center items-center rounded-lg">
            <div className="text-white text-center p-4 bg-gray-800 bg-opacity-80 rounded-lg shadow-lg">
              <p>Please select a customer.</p>
            </div>
          </div>
        )}
        {isLoading && (
          <div className="fixed inset-0 flex justify-center items-center rounded-lg">
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75 bg-sky-500" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

export default ArticleSearch;