import React, { createContext, useContext, ReactNode } from 'react';
import { ArticleBatchListPriceAPI } from 'repositories/article/ArticleAPI';
import { CustomerRepository } from 'repositories/customer/CustomerRepository';
import { ArticleRepository } from 'repositories/article/ArticleRepository';
import { DIContainer } from 'environment/factories/DI';
import { PageView } from 'types/Diagnostics';
import { JWTTokenHook, useHttpJWTToken, useMockedToken } from 'hooks/auth/Token';
import { FetchHook, useRegularFetch, createAuthFetch, withApiHeaders, useUnauthenticatedHandling } from 'hooks/fetch/Fetch';

export interface Config {
  loginUrl: string
  refreshTokenUrl: string
}

interface Dependencies {
  customerRepository: CustomerRepository
  articleRepository: ArticleRepository
  articleBatchListPriceApi: ArticleBatchListPriceAPI
  pageViewTracker: (view: PageView) => void;
  useJwtTokenHook: JWTTokenHook
  config: Config
}

const DIContext = createContext<Dependencies | undefined>(undefined);


export const DIProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  let useToken: JWTTokenHook = useHttpJWTToken;
  let useMockedTokenHook = process.env.REACT_APP_USE_MOCKED_TOKEN_HOOK;
  if (useMockedTokenHook !== undefined && useMockedTokenHook.toLowerCase().startsWith("true")) {
    useToken = useMockedToken;
  }

  let config = {
    loginUrl: DIContainer.LoginURL(),
    refreshTokenUrl: DIContainer.RefreshURL(),
  };

  let useFetch: FetchHook = createAuthFetch(
    config.refreshTokenUrl,
    useUnauthenticatedHandling(
      withApiHeaders(
        useRegularFetch()
      )
    ),
  );
  let useRegularFetchHook = process.env.REACT_APP_USE_REGULAR_FETCH;
  if (useRegularFetchHook !== undefined && useRegularFetchHook.toLowerCase().startsWith("true")) {
    useFetch = useRegularFetch;
  }

  const dependencies: Dependencies = {
    articleBatchListPriceApi: DIContainer.getArticleBatchAPI(useFetch()),
    customerRepository: DIContainer.getCustomerRepository(useFetch()),
    articleRepository: DIContainer.getArticleRepository(useFetch()),
    pageViewTracker: DIContainer.getPageViewTracker(),
    config: config,
    useJwtTokenHook: useToken
  };

  return (
    <DIContext.Provider value={dependencies}>
      {children}
    </DIContext.Provider>
  );
};

export const useDI = () => {
  const context = useContext(DIContext);
  if (!context) {
    throw new Error('useDI must be used within a DIProvider');
  }
  return context;
};