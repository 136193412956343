import React from 'react';
import { Account } from 'types/Customer';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

interface AccountProps {
  account: Account
  onClick: () => void;
  selected: boolean;
}

const AccountItem: React.FC<AccountProps> = ({ account, onClick, selected }) => {
  return (
    <div className="relative mr-4">
      {selected && (
        <div className="absolute top-0 right-0 transform translate-x-1/3 -translate-y-1/3">
          <div className="bg-green-500 rounded-full p-1 h-5 w-5">
            <CheckCircleIcon className="h-3 w-3 text-white" />
          </div>
        </div>
      )}
      <div className="flex flex-col justify-center items-start bg-white rounded-lg shadow p-4" onClick={() => onClick()}>
        <div className="text-lg font-semibold text-gray-800">{account.number}</div>
        <div className="text-sm text-gray-600">{account.name}</div>
      </div>
    </div>
  );
};

export default AccountItem;