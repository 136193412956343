import React, { useEffect, useState } from 'react';
import { MagnifyingGlassIcon, ChevronLeftIcon, ChevronRightIcon, ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline';
import logo192 from 'assets/logo192.png';
import NavLink from './NavLink';
import { useNavigate } from 'react-router-dom';

const SideMenu: React.FC = () => {
  const navigate = useNavigate()
  const [isCollapsed, setIsCollapsed] = useState(true);


  useEffect(() => {
    const handleResize = () => {
      setIsCollapsed(i => window.innerWidth < 768 || i); // Tailwind's 'md' breakpoint
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={`h-full rounded-lg shadow-lg ${isCollapsed ? 'w-16' : 'w-60 p-4'} transition-width duration-300 relative`}>
      <div className={`flex ${isCollapsed ? 'justify-center p-4' : 'pl-2.5'} mb-5`}>
        {!isCollapsed && <img src={process.env.PUBLIC_URL + '/logo.png'} className="m-auto h-6 sm:h-7" alt="Logo" />}
        {isCollapsed && <img src={logo192} className="m-auto h-6 sm:h-7" alt="Logo" />}
      </div>
      <NavLink to='/article-search' text={isCollapsed ? '' : "Article Search"} icon={MagnifyingGlassIcon} collapsed={isCollapsed} />

      <div className="w-full absolute bottom-0 left-0 right-0 items-center justify-center p-4">
        <button onClick={() => navigate("/logout")} className={`transition ease-in-out w-full hover:shadow-sm bg-slate-200 hover:bg-sky-100 items-center flex flex-row gap-2 p-2 justify-center ${isCollapsed ? "rounded-full h-8 w-8": "rounded-md"}`}>
          <ArrowLeftOnRectangleIcon className={`h-4 w-4 ${isCollapsed && "m-auto"}`} />
          {!isCollapsed && "Sign out" }
        </button>
      </div>
      {/* Collapse/Expand button */}
      <button
        className="transition ease-in-out hover:text-sky-450 absolute top-2/4 right-0 transform -translate-x-full mt-2 mr-2"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        {isCollapsed ? (
          <ChevronRightIcon className="transition ease-in-out hover:text-sky-500 h-5 w-5 text-gray-500" />
        ) : (
          <ChevronLeftIcon className="transition ease-in-out hover:text-sky-500 h-5 w-5 text-gray-500" />
        )}
      </button>
    </div>
  );
};

export default SideMenu;