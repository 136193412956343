import { useDI } from 'components/DIContext';
import { useState, useEffect } from 'react';
import { Article, ArticleQuery, CalculateBatchListPriceRequest, CalculateBatchListPriceResponse, SortDirection, SortField } from 'types/Article';

const exludePageResetKeys: (keyof ArticleQuery)[] = ["page", "sortField", "sortDirection"];

export function useArticles(erpDataId: string, accountId: string | null) {
  const { articleRepository } = useDI();
  const [activeFilter, setActiveFilter] = useState<ArticleQuery>({
    articleId: '',
    erpDataId: erpDataId,
    accountId: accountId,
    category: '',
    description: '',
    limit: 100, 
    page: 1,
    sortField: SortField.ArticleID,
    sortDirection: SortDirection.Asc,
  });

  const [articles, setArticles] = useState<Article[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    async function fetchArticles() {
        if (activeFilter.accountId === null || activeFilter.accountId === undefined) {
          setArticles([]);
          return;
        }
        setIsLoading(true);
        var fetchedArticles = await articleRepository.findBy(activeFilter);
        setArticles(fetchedArticles.articles);
        setTotalCount(fetchedArticles.totalCount);
        setIsLoading(false);
    }

    fetchArticles();
  }, [activeFilter, articleRepository]);

  function updateFilter(filterKey: keyof ArticleQuery, value: any) {
    setActiveFilter(prevFilters => {
      if (!exludePageResetKeys.includes(filterKey)) {
        prevFilters.page = 1
      }
      return ({
        ...prevFilters,
        [filterKey]: value
      });
    });
  }

  return { articles, activeFilter, totalCount, isLoading, updateFilter };
}

export function useListPrice() {
  const [filter, setListPriceFilter] = useState<CalculateBatchListPriceRequest | null>(null)
  const [listPrices, setListPrices] = useState<CalculateBatchListPriceResponse | null>(null);
  const { articleBatchListPriceApi } = useDI()


  useEffect(() => {
    if (filter === null) {
      return;
    }

    articleBatchListPriceApi.calculateListPrices(filter, {size: 25, callback: (batch) => setListPrices(batch)});
  }, [filter, articleBatchListPriceApi])

  return {listPrices, setListPriceFilter}
}
