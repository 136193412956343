import Decimal from "decimal.js";
import { Fetcher } from "hooks/fetch/Fetch";
import { ArticleResponse, ArticleQuery, Article, SortField, SortDirection } from "types/Article";



export interface ArticleRepository {
    findBy(query: ArticleQuery): Promise<ArticleResponse>
}

export class InmemoryRepository implements ArticleRepository {
    articles: Article[];

    constructor(articles: Article[]) {
        this.articles = articles;
    }

    findBy(query: ArticleQuery): Promise<ArticleResponse> {
        var resp = {
            totalCount: this.articles.length,
            articles: this.articles.filter((value: Article) => this.matches(query, value))
        };
        return Promise.resolve(resp);
    }

    matches(query: ArticleQuery, value: Article): boolean {
        let match = true;

        if (query.articleId !== "" && query.articleId !== null) {
            match = value.id.toLowerCase().includes(query.articleId.toLowerCase());
        }

        return match;
    }
}

interface HttpArticleSearchResponse {
    currency: string
    totalCount: number
    articles: [
        {
            ItemID: string
            Category: string
            Description: string
            Price: number
            CostPrice?: number
        }
    ]
}

interface HttpArticleSearchBody {
    ItemID: string | null
    Description: string | null
    Category: string | null
}

export class HttpArticleRepository implements ArticleRepository {
    private baseUrl: string;
    private fetch: Fetcher;

    constructor(baseUrl: string, fetcher: Fetcher) {
        this.baseUrl = baseUrl;
        this.fetch = fetcher;
    }

    async findBy(query: ArticleQuery): Promise<ArticleResponse> {

        var offset = (query.page - 1) * query.limit;
        var url = `${this.baseUrl}?erpDataId=${query.erpDataId}&account=${query.accountId!}&limit=${query.limit}&start=${offset}`;
        var body: HttpArticleSearchBody | null = null;

        var sortFieldValue = "";
        switch (query.sortField) {
            case SortField.ArticleID:
                sortFieldValue = "ItemID";
                break;
            case SortField.Category:
                sortFieldValue = "Category";
                break;
            case SortField.CostPrice:
                sortFieldValue = "CostPrice";
                break;
            case SortField.Description:
                sortFieldValue = "Description";
                break;
            case SortField.Price:
                sortFieldValue = "Price";
                break;
        }

        if (sortFieldValue === "") {
            throw new Error("missing sort field value");
        }

        url += `&sortBy=${sortFieldValue}&sortAscending=`;

        if (query.sortDirection === SortDirection.Asc) {
            url += "true";
        } else {
            url += "false";
        }

        if (query.articleId !== "" && query.articleId !== undefined) {
            body = {
                ItemID: query.articleId,
                Description: null,
                Category: null,
            };
        }

        if (query.description !== "" && query.description !== undefined) {
            if (body !== null) {
                body.Description = query.description;
            } else {
                body = {
                    ItemID: null,
                    Description: query.description,
                    Category: null,
                };
            }
        }

        if (query.category !== "" && query.category !== undefined) {
            if (body !== null) {
                body.Category = query.category;
            } else {
                body = {
                    ItemID: null,
                    Description: null,
                    Category: query.category,
                };
            }
        }

        var response = await this.fetch(url, {
            method: 'POST',
            body: body !== null ? JSON.stringify(body) : null
        });

        var data = await response.json() as HttpArticleSearchResponse;

        return {
            totalCount: data.totalCount,
            articles: data.articles.map((val) => {
                return {
                    id: val.ItemID,
                    category: val.Category,
                    description: val.Description,
                    price: new Decimal(val.Price),
                    costPrice: val.CostPrice !== undefined ? new Decimal(val.CostPrice): undefined,
                    currency: data.currency
                }
            }),
        };
    }

}