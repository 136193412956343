import Decimal from "decimal.js";
import { Article } from "types/Article";

interface ListPriceProps {
    article: Article
    listPrice: Decimal | undefined
}

export const ListPrice: React.FC<ListPriceProps> = ({ article, listPrice }) => {
    return (
        <div key={article.id}>{listPrice !== undefined
            ? new Intl.NumberFormat('en-US', { style: 'currency', currency: article.currency }).format(listPrice.toNumber())
            : 'Loading...'}</div>
    );
}