import { useAuth } from 'components/AuthContext';
import { AccessToken, constructAccessToken } from 'hooks/auth/Token';

export type Fetcher = (url: string, options: RequestInit) => Promise<Response>;
export type FetchHook = () => Fetcher;

export class UnauthenticatedError extends Error {

}

let fetchNewToken = async (token: AccessToken, url: string, fetch: Fetcher) => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Authorization', `Bearer ${token.refreshToken}`);
    const response = await fetch(url, { method: "POST", headers: requestHeaders });
    if (!response.ok) {
        throw new Error();
    }
    var data = await response.json();
    return constructAccessToken(data["accessToken"] as string, token.refreshToken);
}


export const createAuthFetch = (refreshTokenUrl: string, innerFetch: Fetcher) => {
    return () => {
        const { token, setToken, handleUnauthenticated } = useAuth();

        const authFetch = async (url: string, options: RequestInit = {}) => {
            if (!token) {
                handleUnauthenticated();
                return Promise.reject(new UnauthenticatedError());
            }

            let tokenToUse = token;

            if ((new Date(tokenToUse.expires)).getTime() <= (new Date()).getTime()) {
                try {
                    tokenToUse = await fetchNewToken(token, refreshTokenUrl, innerFetch);
                    setToken(tokenToUse);
                } catch {
                    handleUnauthenticated();
                }
            }

            const headers = new Headers(options.headers);
            headers.append('Authorization', `Bearer ${tokenToUse.accessToken}`);

            const response = await innerFetch(url, { ...options, headers });

            return response;
        };

        return authFetch;
    }
}

export const withApiHeaders = (fetch: Fetcher) => {
    const withHeadersFetch = async (url: string, options: RequestInit = {}) => {
        const headers = new Headers(options.headers);
        headers.set('Content-Type', 'application/json');
        headers.set('accept', 'application/json');
        return fetch(url, options);
    }

    return withHeadersFetch;
}

export const useUnauthenticatedHandling = (fetch: Fetcher) => {
    const { handleUnauthenticated } = useAuth();
    const withUnauthenticatedHandlingFetch = async (url: string, options: RequestInit = {}) => {
        var response = await fetch(url, options);

        if (response.status === 401) {
            handleUnauthenticated();
            return Promise.reject(new UnauthenticatedError());
        }

        return response;
    }

    return withUnauthenticatedHandlingFetch;
}

export const useRegularFetch = () => {
    return fetch;
}