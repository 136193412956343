import React from 'react';
import { NavLink as Link } from 'react-router-dom';

interface Props {
    to: string
    text: string
    icon: any
    collapsed: boolean
}

const NavLink: React.FC<Props> = ({ to, text, icon, collapsed }) => {
    let element = React.createElement(icon, {className: `h-4 w-4 ${collapsed ? "m-auto":""}`});
    return (
        <Link to={to} className={({ isActive }) => isActive ? `flex items-center text-base font-normal ${collapsed ? "h-8 w-8 rounded-full m-auto": "rounded-lg p-2"} bg-gray-300 text-black` : `${collapsed ? "h-8 w-8 rounded-full m-auto": "rounded-lg p-2"} flex items-center text-base font-normal bg-gray-100 hover:bg-gray-300 hover:text-black`}>
            {element}
            {!collapsed && <span className="px-4">{text}</span>}
        </Link>
    );
};

export default NavLink;