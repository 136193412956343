import { AccessToken, Role } from 'hooks/auth/Token';
import React from 'react';
import { useAuth } from './AuthContext';

interface RoleRendererProps {
    allowedRoles: Role[];
    elementRenderer: (token: AccessToken) => React.ReactNode;
}

const RoleRenderer: React.FC<RoleRendererProps> = ({ allowedRoles, elementRenderer }) => {
    const { token } = useAuth();
    if (token !== null && allowedRoles.some(role => token.activeRole.roleId === role)) {
        return <>{elementRenderer(token)}</>;
    }

    return null;
};

export default RoleRenderer;