import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';


export const AuthGuard = ({ children }: { children: JSX.Element }) => {
    const { token } = useAuth();
    if (!token) {
        return <Navigate to="/login"></Navigate>;
    }

    return children;
};