import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import SideMenu from 'components/SideMenu';
import ArticleSearch from 'components/ArticleSearch';
import { PageViewTracker } from 'hooks/diagnostics/Diagnostics';
import { AuthGuard } from 'components/AuthGuard';
import { Login } from 'components/Login';
import RoleRendererSwitch from 'components/RoleRendererSwitch';
import { Role } from 'hooks/auth/Token';
import { DIProvider } from 'components/DIContext';
import { AuthProvider } from 'components/AuthContext';
import { Logout } from 'components/Logout';

const App: React.FC = () => {
  return (
    <div className="flex flex-row h-screen bg-slate-100">
      <Router>

        <AuthProvider>
          <DIProvider>
            <PageViewTracker />
            <SideMenu />
            <main className="flex-1 overflow-y-auto">
              <Routes>
                <Route path="/login" element={<Login />}></Route>
                <Route path="/logout" element={<Logout />}></Route>
                <Route path="/" element={<Navigate to="/article-search" />}></Route>
                <Route path="/article-search" element={
                  <AuthGuard>
                    <RoleRendererSwitch renderers={
                      new Map([
                        [
                          Role.SalesPerson, (token) => {
                            return (
                              <ArticleSearch companyId={token.activeRole.erpDataId} />
                            );
                          }
                        ],
                        [
                          Role.Customer, (token) => {
                            return (
                              // TODO: Add account id
                              <ArticleSearch companyId={token.activeRole.erpDataId} accountId={token.activeRole.accountNumber} />
                            );
                          }
                        ],
                      ])
                    } />

                  </AuthGuard>} />
                {/* Add other routes as needed */}
              </Routes>
            </main>

          </DIProvider>
        </AuthProvider>
      </Router>
    </div>
  );
};

export default App;