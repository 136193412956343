import React from 'react';

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
    return (
        <div className="sticky bottom-0 backdrop-blur-md w-full bg-slate-50/50">
            <div className="rounded-lg p-4 md:w-1/2 m-auto">
                <div className="flex flex-row gap-4 justify-center items-center">
                    <button
                        className={`transition ease-in-out flex-grow p-2 disabled:hover:cursor-not-allowed enabled:shadow-xs hover:shadow-sm hover:enabled:bg-sky-800 bg-sky-950 text-white  text-center rounded-md`}
                        onClick={() => onPageChange(currentPage - 1)}
                        disabled={currentPage <= 1}
                    >
                        Previous
                    </button>
                    <button
                        className={`transition ease-in-out flex-grow p-2 disabled:hover:cursor-not-allowed enabled:shadow-xs hover:shadow-sm hover:enabled:bg-sky-800 bg-sky-950 text-white  text-center rounded-md`}
                        onClick={() => onPageChange(currentPage + 1)}
                        disabled={currentPage >= totalPages}
                    >
                        Next
                    </button>
                </div>
                <div className="flex justify-center items-center">
                    <p className='m-2'>
                        Page {currentPage} of {totalPages}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Pagination;