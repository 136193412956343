import React from 'react';
import { Article } from 'types/Article';

interface ValueProps {
    header: string
    value: (article: Article) => JSX.Element | string
}

interface ArticleCardProps {
    article: Article;
    onClick: () => void;
    values: ValueProps[]
}

const ArticleCard: React.FC<ArticleCardProps> = ({ article, onClick, values }) => {
    return (
        <div className="border p-4 rounded-lg shadow bg-white" onClick={onClick}>
            <p className="text-lg font-semibold">{article.id + " - " + article.description}</p>
            {values.map((props: ValueProps) => (
            <p className="text-sm text-gray-600">
                {props.header}: {props.value(article)}
            </p>
            ))}
        </div>
    );
};

export default ArticleCard;