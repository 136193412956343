import React, { useState, useEffect, useCallback } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';


interface SearchFieldProps {
    onSearch: (query: string) => void;
    placeholder: string;
    autofocus?: boolean;
}

const SearchField: React.FC<SearchFieldProps> = ({ onSearch, placeholder, autofocus = true }) => {
    const [inputValue, setInputValue] = useState<string>('');
    //const [searchHandler] = useState<(query: string) => void>(onSearch);

    const debouncedSearch = useCallback(() => {
            return debounce(onSearch, 500);
            // eslint-disable-next-line
        }, []);

    useEffect(() => {
        const debounced = debouncedSearch();
        debounced(inputValue);
        return () => {
            debounced.cancel();
        };
    }, [inputValue, debouncedSearch]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    return (
        <div className="flex items-center max-w-md w-full bg-white shadow-md rounded-lg overflow-hidden">
            <input
                autoFocus={autofocus}
                className="w-full px-4 py-2 focus:outline-none"
                type="text"
                placeholder={placeholder}
                value={inputValue}
                onChange={handleInputChange}
            />
            <span className="px-4 text-gray-500">
                <MagnifyingGlassIcon className="h-5 w-5" />
            </span>
        </div>
    );
};

export default SearchField;

// The debounce function remains the same as before
function debounce(func: (...args: any[]) => void, wait: number) {
    let timeout: NodeJS.Timeout | null = null;

    const debouncedFunction = function (...args: any[]) {
        const later = () => {
            timeout = null;
            func(...args);
        };

        if (timeout !== null) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(later, wait);
    };

    debouncedFunction.cancel = function () {
        if (timeout !== null) {
            clearTimeout(timeout);
        }
    };

    return debouncedFunction;
}