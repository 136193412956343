import { useAuth } from "components/AuthContext";
import { useDI } from "components/DIContext";
import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export enum Role {
    SalesPerson = "SalesPerson",
    Customer = "Customer"
}

export type JWTTokenHook = () => [accessToken: AccessToken | null];

interface RoleProperties {
    roleId: Role
    roleName: string
    companyId: string
    companyName: string
    accountNumber?: string
    erpDataId: string
    siteId: string
}

export interface AccessToken {
    accessToken: string
    email: string
    firstName: string
    lastName: string
    displayName: string
    companyId: string
    companyName: string
    activeRole: RoleProperties
    roles: RoleProperties[]
    expires: Date
    refreshToken: string
}

const roleMap: Map<string, Role> = new Map<string, Role>(
    [
        ["STW:Nederman:User", Role.SalesPerson],
        ["STW:Customer:User", Role.Customer],
    ],
)

export const constructAccessToken: (accessToken: string, refreshToken: string) => AccessToken = (accessToken: string, refreshToken: string) => {
    let decodedAccessToken = jwtDecode<any>(accessToken);
    let roles = (decodedAccessToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] as Array<{ roleId: string, roleName: string, companyId: string, companyName: string, accountNumber?: string, erpDataId: string, siteId: string }>).map((val) => {
        if (roleMap.has(val.roleId)) {
            return {
                roleId: roleMap.get(val.roleId),
                roleName: val.roleName,
                companyId: val.companyId,
                companyName: val.companyName,
                accountNumber: val.accountNumber,
                erpDataId: val.erpDataId,
                siteId: val.siteId
            };
        }

        return null
    }).filter((val) => val !== null) as Array<{ roleId: Role, roleName: string, companyId: string, companyName: string, accountNumber?: string, erpDataId: string, siteId: string }>;

    return{
        accessToken: accessToken,
        email: decodedAccessToken["email"] as string,
        firstName: decodedAccessToken["given_name"] as string,
        lastName: decodedAccessToken["family_name"] as string,
        displayName: decodedAccessToken["name"] as string,
        companyName: decodedAccessToken["companyName"],
        companyId: decodedAccessToken["companyÎd"],
        activeRole: roles[0],
        roles: roles,
        expires: (() => {
            let date = new Date(0);
            date.setUTCSeconds(decodedAccessToken["exp"] as number)
            return date;
        })(),
        refreshToken: refreshToken
    };
}

export let useHttpJWTToken: JWTTokenHook = () => {
    const { config } = useDI();
    const [searchParams] = useSearchParams();
    const { token, setToken } = useAuth();

    useEffect(() => {
        let accessToken = searchParams.get("access_token");
        let refreshToken = searchParams.get("refresh_token");
        if (token === null && accessToken === null) {
            window.location.href = config.loginUrl;
            return;
        }

        if (token !== null && token.expires.getTime() >= (new Date()).getTime()) {
            //const response = await fetch(config.refreshTokenUrl, {method: "POST"})
            return;
        }

        if (token !== null) {
            return;
        }

        if (accessToken === null) {
            throw new Error("expected access token");
        }

        if (refreshToken === null) {
            throw new Error("expected refresh token");
        }

        setToken(constructAccessToken(accessToken, refreshToken));
    });

    return [token];
};

export let useMockedToken: JWTTokenHook = () => {
    const { token, setToken } = useAuth();

    setToken({
        accessToken: "mock",
        email: "mock",
        firstName: "mock",
        lastName: "mock",
        displayName: "mock",
        companyName: "mock",
        companyId: "DK2",
        activeRole: {
            roleId: Role.SalesPerson,
            roleName: "sales",
            companyId: "mock",
            companyName: "mock",
            accountNumber: undefined,
            erpDataId: "DK2",
            siteId: "mock"
        },
        roles: [
            {
                roleId: Role.SalesPerson,
                roleName: "sales",
                companyId: "mock",
                companyName: "mock",
                accountNumber: undefined,
                erpDataId: "DK2",
                siteId: "mock"
            }
        ],
        expires: new Date(),
        refreshToken: "mock"
    });



    return [token];
};

