
export enum TextAlignment {
    Left,
    Right
}

interface CellProps<T> {
    header: string
    value: (item: T) => JSX.Element | string
    textAlignment?: TextAlignment
}

interface TableProps<T> {
    id: string
    items: T[]
    cells: CellProps<T>[]
}

export const Table = <T,>(props: React.PropsWithChildren<TableProps<T>>) => {
    return <table key={props.id} className="table-auto overflow-x-auto">
        <thead className="bg-gray-50">
            <tr>
                {props.cells.map((cell: CellProps<T>) => (
                    <th key={cell.header} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                        {cell.header}
                    </th>
                ))}
            </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
            {props.items.map((item: T, index: number) => (
                <tr key={index}>
                    {props.cells.map((cell: CellProps<T>) => <td key={cell.header} className={`px-6 py-4 text-sm text-gray-500 ${cell.textAlignment === TextAlignment.Right ? "text-right": "text-left"}`}>
                        {cell.value(item)}
                    </td>)}
                </tr>
            ))}

        </tbody>
    </table>
}