import { useDI } from 'components/DIContext';
import { useState, useEffect } from 'react';
import { Account, AccountDetails, AccountDetailsQuery, AccountQuery } from 'types/Customer';

export function useAccounts(salesCompanyId?: string) {
  const { customerRepository } = useDI();
  const [filters, setFilters] = useState<AccountQuery>({
    salesCompanyId: salesCompanyId ?? '',
    searchTerm: undefined
  });

  const [accounts, setAccounts] = useState<Account[] | null>(null);

  useEffect(() => {
    async function fetchAccounts() {
      try {
        if (filters.searchTerm === undefined || filters.searchTerm === '') {
          setAccounts(null);
          return;
        }

        const fetchedAccounts = await customerRepository.findBy(filters);
        setAccounts(fetchedAccounts);
      } catch (error) {
        // Handle errors, e.g. set an error state, log, etc.
      }
    }

    fetchAccounts();
  }, [filters, customerRepository]);

  function updateFilter(filterKey: keyof AccountQuery, value: string) {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterKey]: value
    }));
  }

  return { accounts, updateFilter };
}

export function useAccountDetails(salesCompanyId: string | undefined, number: string | undefined) {
  const { customerRepository } = useDI();
  const [filters, setFilters] = useState<AccountDetailsQuery>({
    salesCompanyId: salesCompanyId,
    number: number
  });

  const [accountDetails, setAccountDetails] = useState<AccountDetails | null>(null);

  useEffect(() => {
    async function fetchDetails() {
      setAccountDetails(null);
      if (filters.salesCompanyId === undefined || filters.number === undefined || filters.number === '' || filters.salesCompanyId === '') {
        return;
      }

      const details = await customerRepository.findDetailsBy(filters);
      if (details === undefined) {
        throw new Error("could not find account details")
      }
      setAccountDetails(details);
    }

    fetchDetails();
  }, [filters, customerRepository]);

  function updateAccountDetailsFilter(filterKey: keyof AccountDetailsQuery, value: string) {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterKey]: value
    }));
  }

  return { accountDetails, updateAccountDetailsFilter };
}