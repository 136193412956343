import { AccessToken } from 'hooks/auth/Token';
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

interface AuthContextType {
    token: AccessToken | null;
    setToken: (token: AccessToken | null) => void;
    handleUnauthenticated: () => void;
    signOut: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within a AuthProvider');
    }
    return context;
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const navigate = useNavigate();

    const [token, setToken] = useState<AccessToken | null>(() => {
        const storedToken = localStorage.getItem('accessToken');
        if (storedToken === null) {
            return null;
        }
        return JSON.parse(storedToken) as AccessToken;
    });

    const handleUnauthenticated = () => {
        setToken(null);
        navigate('/login');
    };

    const signOut = () => {
        setToken(null);
    }


    useEffect(() => {
        if (token === null) {
            localStorage.removeItem('accessToken');
        } else {
            localStorage.setItem('accessToken', JSON.stringify(token));
        }
    }, [token]);

    return (
        <AuthContext.Provider value={{ token, setToken, handleUnauthenticated, signOut }}>
            {children}
        </AuthContext.Provider>
    );
};