import { Fetcher } from "hooks/fetch/Fetch";
import { Account, AccountDetails, AccountDetailsQuery, AccountQuery } from "types/Customer";

export interface CustomerRepository {
    findBy(query: AccountQuery): Promise<Account[]>
    findDetailsBy(query: AccountDetailsQuery): Promise<AccountDetails | undefined>
}

export class InmemoryCustomerRepository implements CustomerRepository {
    private accounts: Account[];

    constructor(accounts: Account[]) {
        this.accounts = accounts;
    }
    
    async findDetailsBy(query: AccountDetailsQuery): Promise<AccountDetails | undefined> {
        throw new Error("Method not implemented.");
    }

    async findBy(query: AccountQuery): Promise<Account[]> {
        // Filter accounts based on the salesCompanyId
        return this.accounts.filter(account => account.salesCompanyId === query.salesCompanyId);
    }
}

interface HttpCustomerSearchResponse {
    customers: [
        {
            siteId: string
            accountNum: string
            isBlocked: string
            name: string
        }
    ]
}

export class HttpCustomerRepository implements CustomerRepository {
    private baseUrl: string;
    private baseDetailsUrl: string;
    private functionKey: string;
    private fetch: Fetcher;

    constructor(baseUrl: string, baseDetailsUrl: string, functionKey: string, fetcher: Fetcher) {
        this.baseUrl = baseUrl;
        this.functionKey = functionKey;
        this.baseDetailsUrl = baseDetailsUrl;
        this.fetch = fetcher;
    }
    async findDetailsBy(query: AccountDetailsQuery): Promise<AccountDetails | undefined> {
        if (query.salesCompanyId === undefined || query.salesCompanyId === '') {
            throw new Error("HttpCustomerRepository.findDetailsBy: salesCompanyId must be set");
        }

        if (query.number === undefined || query.number === '') {
            throw new Error("HttpCustomerRepository.findDetailsBy: account number must be set");
        }

        var response = await this.fetch(`${this.baseDetailsUrl}?ErpDataId=${query.salesCompanyId}&accountNum=${query.number}`, {
            method: 'GET',
        });

        if (response.status === 404) {
            return undefined;
        }

        var json = await response.json();

        return {
            priceGroup: json['priceGroup'] !== null && json['priceGroup'] !== "" ? json['priceGroup']: undefined,
            discountGroup: json['discountGroup']
        };
    }
    
    async findBy(query: AccountQuery): Promise<Account[]> {
        if (query.searchTerm === undefined || query.searchTerm === '') {
            throw new Error("HttpCustomerRepository.findBy: searchTerm must be set");
        }

        var response = await this.fetch(`${this.baseUrl}?erpDataId=${query.salesCompanyId}&query=${query.searchTerm}&limit=100`, {
            method: 'POST',
        });

        if (response.status === 404) {
            return [];
        }

        var json = await response.json();
        var data = {
            customers: json
        } as HttpCustomerSearchResponse;

        return data.customers.map((val) => {
            return {
                salesCompanyId: query.salesCompanyId,
                siteId: val.siteId,
                number: val.accountNum,
                name: val.name,
                isBlocked: val.isBlocked === "0" ? true : false,
            }
        });
    }

}