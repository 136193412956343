import Decimal from "decimal.js";
import { Fetcher } from "hooks/fetch/Fetch";
import { CalculateBatchListPriceRequest, CalculateBatchListPriceResponse, CalculateListPriceRequest, CalculateListPriceResponse } from "types/Article";



export interface ArticleListPriceAPI {
    calculateListPrice(request: CalculateListPriceRequest): Promise<CalculateListPriceResponse>;
}

export type BatchCallback = (batch: CalculateBatchListPriceResponse) => void;

export interface BatchConfig {
    size: number
    callback: BatchCallback
}

export interface ArticleBatchListPriceAPI {
    calculateListPrices(request: CalculateBatchListPriceRequest, batchConfig: BatchConfig): Promise<void>;
}

export class InmemoryArticleAPI implements ArticleListPriceAPI {
    calculateListPrice(request: CalculateListPriceRequest): Promise<CalculateListPriceResponse> {
        return Promise.resolve({
            articleId: request.id,
            price: new Decimal(1000),
        });
    }
}

export class CachedArticleListPriceAPI implements ArticleListPriceAPI {
    private cache: Map<string, CalculateListPriceResponse> = new Map<string, CalculateListPriceResponse>();

    private next: ArticleListPriceAPI;

    constructor(next: ArticleListPriceAPI) {
        this.next = next;
    }


    async calculateListPrice(request: CalculateListPriceRequest): Promise<CalculateListPriceResponse> {
        let cacheKey = this.resolveCacheKey(request);
        let response = this.cache.get(cacheKey);
        if (response !== undefined) {
            return Promise.resolve(response);
        }

        response = await this.next.calculateListPrice(request);
        this.cache.set(cacheKey, response);
        return response;
    }

    resolveCacheKey(request: CalculateListPriceRequest): string {
        return request.accountNumber + ":" + request.id;
    }
}

interface HttpArticleListPriceItem {
    itemId: string
    priceGroup: string
    currency: string
    grossPrice: number
    netPrice: number
    discountGroup: ""
    discountPercent: number
    salesLeadTime: string
}

export class HttpArticleListPriceAPI implements ArticleBatchListPriceAPI {
    private baseUrl: string;
    private fetch: Fetcher;

    constructor(baseUrl: string, fetcher: Fetcher) {
        this.baseUrl = baseUrl;
        this.fetch = fetcher;
    }

    async calculateListPrices(request: CalculateBatchListPriceRequest, batchConfig: BatchConfig): Promise<void> {
        var batches = batchReduce(request.ids, batchConfig.size)
        for(var batch of batches) {
            var url = `${this.baseUrl}?erpDataId=${request.salesCompanyId}&accountNum=${request.accountNumber}&itemIds=${encodeURIComponent(batch.join(','))}&currency=${request.currency}`;
            if (request.discountGroup !== undefined && request.discountGroup !== null && request.discountGroup !== "") {
                url += `&discountGroup=${request.discountGroup}`;
            }
    
            if (request.priceGroup !== undefined && request.priceGroup !== null && request.priceGroup !== "") {
                url += `&priceGroup=${request.priceGroup}`;
            }
    
            var response = await this.fetch(url, {
                method: 'GET',
            });
    
            var data = await response.json() as Array<HttpArticleListPriceItem>;

            var result = data.reduce<CalculateBatchListPriceResponse>((pv, cv) => {
                pv.prices.push({
                    articleId: cv.itemId,
                    price: new Decimal(cv.netPrice)
                });
                return pv;
            }, {
                prices: new Array<{ articleId: string, price: Decimal }>()
            })

            batchConfig.callback(result)
        } 
        
        return;
    }
}

function batchReduce<T>(arr: T[], batchSize: number): T[][] {
    return arr.reduce((batches, curr, i) => {
        if (i % batchSize === 0) batches.push([]);
        batches[batches.length - 1].push(arr[i]);
        return batches;
    }, [] as T[][]);
}
