import { Decimal } from 'decimal.js';

export interface Article {
    id: string
    category: string
    description: string
    price: Decimal
    costPrice?: Decimal
    currency: string
}

export interface ArticleResponse {
    totalCount: number
    articles: Article[]
}

export enum SortField {
    ArticleID,
    Category,
    Description,
    Price,
    CostPrice,
}

export enum SortDirection {
    Asc,
    Desc
}

export interface ArticleQuery {
    erpDataId: string,
    accountId: string | null,
    articleId: string,
    category: string,
    description: string,
    limit: number,
    page: number,
    sortField: SortField,
    sortDirection: SortDirection,
}

export interface CalculateListPriceResponse {
    articleId: string
    price: Decimal
}

export interface CalculateListPriceRequest {
    id: string
    accountNumber: string
    salesCompanyId: string
    currency: string
    discountGroup: string
    priceGroup?: string
}

export interface CalculateBatchListPriceRequest {
    ids: string[]
    accountNumber: string
    currency: string
    discountGroup: string
    priceGroup?: string
    salesCompanyId: string
}

export interface CalculateBatchListPriceResponse {
    prices: Array<
        {
            articleId: string
            price: Decimal
        }
    >
}