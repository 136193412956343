import Decimal from "decimal.js";
import { ArticleRepository, HttpArticleRepository, InmemoryRepository } from "repositories/article/ArticleRepository";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ArticleQuery, ArticleResponse, CalculateListPriceRequest, CalculateListPriceResponse } from "types/Article";
import { v4 as uuidv4 } from 'uuid';
import reportWebVitals from "reportWebVitals";
import { PageView } from "types/Diagnostics";
import { ArticleBatchListPriceAPI, ArticleListPriceAPI, HttpArticleListPriceAPI } from "repositories/article/ArticleAPI";
import { CustomerRepository, HttpCustomerRepository, InmemoryCustomerRepository } from "repositories/customer/CustomerRepository";
import { Account, AccountDetails, AccountDetailsQuery, AccountQuery } from "types/Customer";
import { Fetcher } from "hooks/fetch/Fetch";

enum Environment {
  local,
  dev,
  test,
  prod
}

export class DIContainer {

  static environment: Environment | null = null;
  static appInsights: ApplicationInsights | null = null;
  static listPriceAPI: ArticleListPriceAPI | null = null;

  private static getEnvironment(): Environment {
    if (this.environment == null) {
      throw new Error('environment not set');
    }

    return this.environment;
  }

  private static isEnvironment(env: Environment): boolean {
    return this.getEnvironment() === env;
  }

  private static isAnyEnvironment(...envs: Environment[]): boolean {
    for (var env of envs) {
      if (this.getEnvironment() === env) {
        return true;
      }
    }
    return false;
  }

  private static setEnvironment() {
    let env = process.env.REACT_APP_ENVIRONMENT;
    if (env === "" || env === undefined) {
      throw new Error('environment not set');
    }

    switch (env) {
      case "local":
        this.environment = Environment.local;
        break;
      case "dev":
        this.environment = Environment.dev;
        break;
      case "test":
        this.environment = Environment.test;
        break;
      case "prod":
        this.environment = Environment.prod;
        break;
      default:
        throw new Error('unrecognized environment: ' + env);
    }

  }

  static init() {
    this.setEnvironment();
    let appInsightsKey = process.env.REACT_APP_APP_INSIGHTS_KEY;
    if (appInsightsKey !== undefined) {
      // TODO: Configure more
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: appInsightsKey,
        }
      })
      this.appInsights.loadAppInsights();
    }

    if (DIContainer.appInsights !== null) {
      reportWebVitals((metric) => {
        const { name, delta, value, id } = metric;
        DIContainer.appInsights!.trackEvent({
          name: `web-vital-${name}`,
          properties: {
            name,
            delta: delta.toString(),
            value: value.toString(),
            id,
            entries: metric.entries,
          }
        });
      });
    } else {
      reportWebVitals(console.log);
    }
  }

  static getPageViewTracker(): (view: PageView) => void {
    return (view: PageView) => {
      if (this.appInsights != null) {
        this.appInsights.trackPageView(view);
      }
    };
  }

  static getArticleBatchAPI(fetcher: Fetcher): ArticleBatchListPriceAPI {
    let api: ArticleBatchListPriceAPI | null = null;
    if (this.isEnvironment(Environment.local)) {
      //api = new InmemoryArticleAPI();
    } else if (this.isAnyEnvironment(Environment.dev, Environment.test, Environment.prod)) {
      api = new HttpArticleListPriceAPI(
        process.env.REACT_APP_ARTICLE_LIST_PRICE_BASE_URL as string,
        fetcher,
      )
    }

    if (api != null) {
      //api = new CachedArticleListPriceAPI(api);
      if (this.appInsights != null) {
        //api = this.ArticleAPIWithTelemetry(api, this.appInsights)
      }

      return api;
    }
    throw new Error("missing ArticleAPI implementation for environment " + process.env.REACT_APP_ENVIRONMENT);
  }

  static getCustomerRepository(fetcher: Fetcher): CustomerRepository {
    let repo: CustomerRepository | null = null;
    if (this.isEnvironment(Environment.local)) {
      repo = new InmemoryCustomerRepository([
        { number: '12345', name: 'Acme Corporation', salesCompanyId: '001', siteId: "123", isBlocked: false },
        { number: '12345', name: 'Acme Corporation', salesCompanyId: '001', siteId: "123", isBlocked: false },
        { number: '12345', name: 'Acme Corporation', salesCompanyId: '001', siteId: "123", isBlocked: false },
        { number: '12345', name: 'Acme Corporation', salesCompanyId: '001', siteId: "123", isBlocked: false },
        { number: '12345', name: 'Acme Corporation', salesCompanyId: '001', siteId: "123", isBlocked: false },
        { number: '12345', name: 'Acme Corporation', salesCompanyId: '001', siteId: "123", isBlocked: false },
        { number: '12345', name: 'Acme Corporation', salesCompanyId: '001', siteId: "123", isBlocked: false },
        { number: '12345', name: 'Acme Corporation', salesCompanyId: '001', siteId: "123", isBlocked: false },
        { number: '12345', name: 'Acme Corporation', salesCompanyId: '001', siteId: "123", isBlocked: false },
        { number: '12345', name: 'Acme Corporation', salesCompanyId: '001', siteId: "123", isBlocked: false },
        { number: '12345', name: 'Acme Corporation', salesCompanyId: '001', siteId: "123", isBlocked: false },
        { number: '12345', name: 'Acme Corporation', salesCompanyId: '001', siteId: "123", isBlocked: false },
      ]);
    } else if (this.isAnyEnvironment(Environment.dev, Environment.test, Environment.prod)) {
      repo = new HttpCustomerRepository(
        process.env.REACT_APP_CUSTOMER_SEARCH_BASE_URL as string,
        process.env.REACT_APP_CUSTOMER_DETAILS_BASE_URL as string,
        process.env.REACT_APP_FUNCTIONS_KEY as string,
        fetcher,
      );
    }

    if (repo != null) {
      if (this.appInsights != null) {
        repo = this.CustomerRepositoryWithTelemetry(repo, this.appInsights)
      }

      return repo;
    }
    throw new Error("missing ArticleRepository implementation for environment " + process.env.REACT_APP_ENVIRONMENT);
  }

  static getArticleRepository(fetcher: Fetcher): ArticleRepository {
    let repo: ArticleRepository | null = null;
    if (this.isEnvironment(Environment.local)) {
      repo = new InmemoryRepository([
        {
          id: "10230040",
          category: "Extr. Arms, kit and Welding tables",
          description: "Kit fume extractor 3m orig. 400V",
          price: new Decimal(15487),
          costPrice: new Decimal(5745.71),
          currency: "USD"
          //listPrice: new Decimal(15487),
        },
        {
          id: "10230050",
          category: "Extr. Arms, kit and Welding tables",
          description: "Kit fume extractor 4mv orig. 400V",
          price: new Decimal(16309),
          costPrice: new Decimal(6050.44),
          currency: "USD"
          //listPrice: new Decimal(16309),
        },
        {
          id: "10311238",
          category: "Common accessories Capture & Extraction units",
          description: "Spot light fume extractor orig.",
          price: new Decimal(1818),
          costPrice: new Decimal(632.81),
          currency: "USD"
          //listPrice: new Decimal(1818),
        },
        // ... Add additional articles as needed ...
      ]);
    } else if (this.isAnyEnvironment(Environment.dev, Environment.test, Environment.prod)) {
      repo = new HttpArticleRepository(
        process.env.REACT_APP_ARTICLE_SEARCH_BASE_URL as string,
        fetcher,
      );
    }

    if (repo != null) {
      if (this.appInsights != null) {
        repo = this.ArticleRepositoryWithTelemetry(repo, this.appInsights)
      }

      return repo;
    }
    throw new Error("missing ArticleRepository implementation for environment " + this.getEnvironment());
  }


  static ArticleRepositoryWithTelemetry(repository: ArticleRepository, appInsights: ApplicationInsights): ArticleRepository {
    return {
      async findBy(query: ArticleQuery): Promise<ArticleResponse> {
        return await DIContainer.WithTelemetry((query: ArticleQuery) => repository.findBy(query), appInsights, 'ArticleRepository.findBy')(query);
      }
    }
  }

  static ArticleAPIWithTelemetry(api: ArticleListPriceAPI, appInsights: ApplicationInsights): ArticleListPriceAPI {
    return {
      async calculateListPrice(request: CalculateListPriceRequest): Promise<CalculateListPriceResponse> {
        return DIContainer.WithTelemetry((request: CalculateListPriceRequest) => api.calculateListPrice(request), appInsights, 'ArticleAPI.calculateListPrice')(request);
      }
    }
  }

  static CustomerRepositoryWithTelemetry(repository: CustomerRepository, appInsights: ApplicationInsights): CustomerRepository {
    return {
      async findBy(query: AccountQuery): Promise<Account[]> {
        return await DIContainer.WithTelemetry((query: AccountQuery) => repository.findBy(query), appInsights, 'ArticleRepository.findBy')(query);
      },

      async findDetailsBy(query: AccountDetailsQuery): Promise<AccountDetails | undefined> {
        return await DIContainer.WithTelemetry((query: AccountDetailsQuery) => repository.findDetailsBy(query), appInsights, 'ArticleRepository.findDetailsBy')(query);
      }
    }
  }

  static WithTelemetry<T extends (...args: any[]) => Promise<any>>(
    func: T,
    appInsights: ApplicationInsights,
    functionName: string
  ): T {
    return (async (...args: any[]) => {
      let id = uuidv4();
      const start = performance.now();
      try {
        const result = await func(...args);
        const end = performance.now();

        appInsights.trackDependencyData({
          id: id,
          name: `${functionName}`,
          duration: Math.round(end - start),
          success: true,
          responseCode: 200,
          data: JSON.stringify(args),
          type: 'API',
        });

        return result;
      } catch (error) {
        const end = performance.now();
        appInsights.trackException({ exception: error as Error });
        appInsights.trackDependencyData({
          id: id,
          name: `${functionName}`,
          duration: Math.round(end - start),
          success: false,
          responseCode: 500,
          data: JSON.stringify(args),
          type: 'API',
        });

        throw error;
      }
    }) as T;
  }

  static LoginURL(): string {
    let url = process.env.REACT_APP_LOGIN_URL;
    if (url === "" || url === undefined) {
      throw new Error('Login url not set');
    }

    return url;
  }

  static RefreshURL(): string {
    let url = process.env.REACT_APP_REFRESH_TOKEN_URL;
    if (url === "" || url === undefined) {
      throw new Error('Refresh token url not set');
    }

    return url;
  }
}
