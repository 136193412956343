import { useDI } from "components/DIContext";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const PageViewTracker = () => {
    const location = useLocation();
    const {pageViewTracker} = useDI();

    useEffect(() => {
        pageViewTracker({
            name: location.pathname,
            uri: window.location.href,
        });
    }, [location, pageViewTracker]);

    return null;
};