import React, { useRef, useState } from 'react';
import AccountItem from './AccountItem';
import { useAccounts } from 'hooks/customer/Customer';
import SearchField from 'components/SearchField';
import { Account } from 'types/Customer';
import DocumentTextIcon from '@heroicons/react/24/outline/DocumentTextIcon';

interface AccountListProps {
    salesCompanyId: string;
    onSelected: (account: Account) => void;
    onSearch?: () => void;
}

const AccountList: React.FC<AccountListProps> = ({ salesCompanyId, onSelected, onSearch }) => {
    const { accounts, updateFilter } = useAccounts(salesCompanyId);
    const listRef = useRef<HTMLDivElement>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [selected, setSelected] = useState<Account | null>(null);

    
    const search = (query: string) => {
        updateFilter('searchTerm', query);
        if (onSearch !== undefined) {
            onSearch();
        }
    }
    const onMouseDown = (e: React.MouseEvent) => {
        setIsDragging(true);
        setStartX(e.pageX - listRef.current?.offsetLeft!);
        setScrollLeft(listRef.current?.scrollLeft!);
    };

    const onMouseLeave = () => {
        if (isDragging) setIsDragging(false);
    };

    const onMouseUp = () => {
        setIsDragging(false);
    };

    const onMouseMove = (e: React.MouseEvent) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - listRef.current?.offsetLeft!;
        const walk = (x - startX) * 2; // The number 2 can be adjusted to make scroll faster or slower
        if (listRef.current) {
            listRef.current.scrollLeft = scrollLeft - walk;
        }
    };

    const onSelect = (account: Account) => {
        setSelected(account);
        onSelected(account);
    }

    return (
        <div className="rounded-lg shadow-inner p-4 mr-4">
            <div className="flex mb-8 mx-auto justify-center items-center">
                <SearchField onSearch={(query) => { search(query) }} placeholder='Search customers' />
            </div>
            {accounts !== null && accounts.length === 0 ? <div className="flex flex-col items-center justify-center">
                <DocumentTextIcon className="h-12 w-12 text-gray-400" />
                <p className="text-gray-400 mt-2">No accounts</p>
            </div> : null}
            {accounts !== null && accounts.length > 0 ? <div
                ref={listRef}
                onMouseDown={onMouseDown}
                onMouseLeave={onMouseLeave}
                onMouseUp={onMouseUp}
                onMouseMove={onMouseMove}
                className="flex flex-nowrap overflow-x-scroll py-2 px-2 space-x-4 cursor-grab"
            >

                {accounts.map(account => (
                    <AccountItem key={account.number} account={account} onClick={() => { onSelect(account) }} selected={account === selected} />
                ))}
            </div> : null}
        </div>
    );
};

export default AccountList;